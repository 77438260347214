export const BASE_URL = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`; // domain/api
export const ADMIN_API = `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_API_ADMIN}`; // domain/api/admin
export const ZIGGEO_TOKEN = `${process.env.VUE_APP_ZIGGEO_TOKEN}`
export const CURSOS_URL = `https://cursos.talento-ephos.trestristestigres.com`

export const USUARIO_FIELDS = {
  name: '',
  email: '',
  password: '',
  repeatPassword: '',
  activo: true,
}

export const MEDICO_FIELDS = {
  name: '',
  email: '',
  password: '',
  repeatPassword: '',
  numero_colegiado: '',
  activo: true,
}

export const CENTRO_FIELDS = {
  nombre: '',
  persona_contacto: '',
  telefono: '',
  email: '',
  saldo: '',
  activo: true
}

export const PACIENTE_FIELDS = {
  centro_medico_id: '',
  nombre: '',
  apellidos: '',
  movil: '',
  sexo: '',
  nacimiento: '',
  codigo_postal: '',
  dni: '',
  email: ''
}

export const LESION_FIELDS = {
  titulo: '',
  categoria_id: '',
  coste: '',
  descripcion: '',
  centro_id: ''
}

export const EXPEDIENTE_FIELDS = {
  titulo: '',
  centro_id: '',
  paciente_id: '',
  medico_id: '',
  estado_id: 1,
  fecha: '',
  coste: 0,
  observaciones: '',
}

export const SUSTITUCIONES_FIELDS = {
  fecha_inicio: '',
  fecha_fin: '',
  medico: ''
}

export const TIPOLOGIA_PADRE_FIELDS = {
  nombre: '',
  // customizada: false,
  // ruta: null
}

export const PRODUCTO_FIELDS = {
  nombre: '',
  precio: 0,
  tipo_id: '',
  descripcion: ''
}

export const CUOTA_FIELDS = {
  tipo_id: '',
  estado_id: 221,
  concepto: '',
  importe: '',
  producto_id: '',
  descuento: '',
  descuento_importe: '',
}

export const MASTER_FIELDS = {
  nombre: '',
  abreviatura: '',
  sede_id: null,
  curso_id: null,
  tipo_id: null,
  sector_id: null,
  titulo: '',
  subtitulo: '',
  fecha_inicio: '',
  activo: true,
  formulario_activo: false,
  aula_virtual: false,
  streaming: false,
  alumnos_objetivo: null,
  creditos_totales: null,
  creditos_practicas: null,
  importe_minimo_beca: null,
  descripcion: '',
  formulario_activo: true,
}

export const GASTOS_FIELDS = {
  fecha: '',
  concepto: '',
  importe: ''
}

export const PROFESOR_FIELDS = {
  nombre: '',
  apellidos: '',
  identificacion: '',
  telefono: '',
  email: '',
  empresa: '',
  cargo: '',
  activo: true,
  acepta_newsletter: false,
  cuenta_bancaria: '',
  tipo_pago: '',
  retencion: '',
  iva: '',
  tarifa_viaje: 200,
}

export const DIRECCIONES_FIELDS = {
  principal: false,
  alias: '',
  tipo_id: '',
  direccion: '',
  localidad: '',
  codigo_postal: '',
  provincia: '',
  pais: '',
  observaciones: '',
  nombre_fiscal: '',
  cif: '',
}

export const CONTACTO_FIELDS = {
  nombre: '',
  primer_apellido: '',
  segundo_apellido: '',
  email: '',
  telefono: '',
  politica_privacidad: false,
  acepta_newsletter: false
}

export const BECA_FIELDS = {
  nombre: '',
  empresa_id: null,
  estado_id: null,
  departamentos: [],
  sede_id: null,
  curso_id: null,
  tipo_beca_id: null,
  sector_id: null,
  nivel_minimo_ingles_id: null,
  fecha_incorporacion: '',
  fecha_fin_practicas: '',
  actividades_desarrollar_alumno: '',
  descripcion: '',
  condiciones_economicas: null,
  tutor_practicas: null,
}

export const CONVOCATORIA_FIELDS = {
  modalidad_id: null,
  nombre: '',
  tipo_id: null,
  fecha: '',
  sede_id: null,
  observaciones: '',
  enlace_zoom: '',
  temas: '',
}

export const GRUPO_FIELDS = {
  nombre: '',
  numero_alumnos: '',
}

export const MOVIMIENTO_FIELDS = {
  fecha: '',
  concepto: '',
  importe: '',
  cuotas: [],
}

export const FACTURA_FIELDS = {
  fecha: '',
  numero: '',
  codigo: '',
  estado_id: '',
  tipo: '',
  importe: '',
  direccion: '',
  localidad: '',
  provincia: '',
  codigo_postal: '',
  pais: '',
  nif: '',
  descripcion: '',
  base_imponible: '',
  importe_iva: '',
  iva: '',
  venta_id: '',
  contacto_id: '',
}

export const LISTA_FIELDS = {
  nombre: '',
  entidad: '',
}

export const LINEA_FIELDS = {
  concepto: '',
  cantidad: '',
  base_imponible: '',
  importe: '',
  importe_iva: '',
  iva: '',
}

export const FORO_VISION_FIELDS = {
  titulo: '',
  email: '',
  descripcion: '', // texto de primer comentario de foro
  profesor_id: null,
  tipo_id: 2,
  principal: false,
}

export const REUNION_INFORMATIVA_FIELDS = {
  fecha: '',
  sede_id: '',
  modalidad_id: '',
  lugar: '',
  zoom: '',
}
// Borrador
// Pendiente diagnóstico
// Pendiente diagnóstico (parcial)
// Diagnosticado 
// Aceptado por cliente

export const ESTADOS_EXPEDIENTE = [
  // {
  //     "value": 16,
  //     "label": "Creado",
  //     "clave": "creado",
  //     "peso": 0,
  //     "level": 1,
  //     "parent_id": 15
  // },
  {
      "value": 17,
      "label": "Borrador",
      "clave": "pendiente",
      "peso": 1,
      "level": 1,
      "parent_id": 15
  },
  {
      "value": 18,
      "label": "Pendiente diagnóstico",
      "clave": "requiere-atencion",
      "peso": 2,
      "level": 1,
      "parent_id": 15
  },
  {
      "value": 19,
      "label": "Pendiente diagnóstico (parcial)",
      "clave": "diagnosticado",
      "peso": 3,
      "level": 1,
      "parent_id": 15
  },
  {
      "value": 20,
      "label": "Diagnosticado",
      "clave": "notificado",
      "peso": 4,
      "level": 1,
      "parent_id": 15
  },
  {
      "value": 21,
      "label": "Aceptado por cliente",
      "clave": "aceptado-por-cliente",
      "peso": 5,
      "level": 1,
      "parent_id": 15
  },
  // {
  //     "id": 22,
  //     "nombre": "Rechazado",
  //     "clave": "rechazado",
  //     "peso": 6,
  //     "level": 1,
  //     "parent_id": 15
  // }
]
// export const ESTADOS_EXPEDIENTE = [
  
//   {
//     label: 'Borrador',
//     value: 1
//   },
//   {
//       label: 'Pendiente diagnóstico',
//       value: 2
//   },
//   {
//       label: 'Pendiente diagnóstico (parcial)',
//       value: 3
//   },
//   {
//       label: 'Diagnosticado',
//       value: 4
//   },
//   {
//       label: 'Aceptado por cliente',
//       value: 5
//   },
//   {
//     label: 'Expediente de urgencia',
//     value: 5
//   }

// ]

export const ESTADOS_LESION = [
 
    {
        "value": 29,
        "label": "Pendiente",
        "clave": "pendiente",
        "peso": 0,
        "level": 1,
        "parent_id": 28
    },
    {
        "value": 30,
        "label": "Apta",
        "clave": "apta",
        "peso": 1,
        "level": 1,
        "parent_id": 28
    },
    {
        "value": 31,
        "label": "No apta",
        "clave": "no-apta",
        "peso": 2,
        "level": 1,
        "parent_id": 28
    },
    {
        "value": 32,
        "label": "Denegada",
        "clave": "denegada",
        "peso": 3,
        "level": 1,
        "parent_id": 28
    } 
  // {
  //   value: 1,
  //   label: 'Pendiente'
  // },
  // {
  //     value: 2,
  //     label: 'Apta'
  // },
  // {
  //   value: 2,
  //   label: 'No Apta'
  // },
  // {
  //   value: 2,
  //   label: 'Denegada'
  // }
]

export const CATEGORIAS_LESION = [
  {
      label: 'Nevus',
      value:1
  },
  {
      label: 'Milium Sebaceo',
      value:2
  },
  {
      label: 'Léntigo',
      value:3
  },
  {
      label: 'Araña Vascular',
      value:4
  },
  {
      label: 'Pápulas Fibrosas',
      value:5
  },
  {
      label: 'Acrocordones',
      value:6
  },
  {
      label: 'Punto Rubí',
      value:7
  },
  {
      label: 'Otros',
      value:8
  }
]

export const ESTADOS_FILTRO_CANDIDATO = [
  { label: 'Todos', value: 'todos'},
  { label: 'Asignados', value: 'asignados'},
  { label: 'No asignados', value: 'no asignados'},
  { label: 'No contratado', value: 'no contratado'},
  { label: 'Contratado', value: 'contratado'},
];

export const ESTADOS_CANDIDATO_BECA = [
  {id: 357, nombre: "Contratado"},
  {id: 356, nombre: "Pendiente"},
  {id: 359, nombre: "Renuncia"},
  {id: 358, nombre: "Rescindido"},
];

export const ESTADOS_CANDIDATURA = [
  {clave: 'convocado', nombre: "Convocado"},
  {clave: 'no_convocado', nombre: "No convocado"},
  {clave: 'confirmada_convocatoria', nombre: "Confirmada convocatoria"},
  {clave: 'rechazada_convocatoria', nombre: "Rechazada convocatoria"},
  {clave: 'entrevista_realizada', nombre: "Entrevista realizada"},
  {clave: 'entrevista_no_realizada', nombre: "Entrevista no realizada"},
];

// Estados estado beca
export const STATUS_BECA_CERRADA = "Cerrada contratación";

export const ESTADOS_INFORME_BECA = [
  {id: 423, nombre: "Abierto"},
  {id: 424, nombre: "En curso"},
  {id: 425, nombre: "Cerrado"},
];

export const ESTADOS_MEMORIA_INFORME_BECA = [
  {id: 357, nombre: "Pendiente notificación"},
  {id: 356, nombre: "Notificado"},
  {id: 359, nombre: "Realizado"},
];

export const ESTADOS_SELECCION_MATRICULA = [
  {id: 277, nombre: "Pendiente"},
  {id: 278, nombre: "Rechazado"},
  {id: 279, nombre: "Aceptado"},
  {id: 280, nombre: "No presentado"},
  {id: 281, nombre: "Desiste"},
];

export const ESTADOS_PRESELECCION_MATRICULA = [
  {id: 272, nombre: "Pendiente"},
  {id: 273, nombre: "Rechazado"},
  {id: 274, nombre: "Aceptado"},
  {id: 275, nombre: "Desiste"},
];

export const ESTADOS_MATRICULA = [
  {id: 265, nombre: "Pendiente"},
  {id: 266, nombre: "Confirmada"},
  {id: 267, nombre: "Pendiente documentación o pago"},
  {id: 268, nombre: "Matriculado"},
  {id: 269, nombre: "Finalizada"},
  {id: 270, nombre: "Baja"},
];

export const ESTADO_MATRICULA_MATRICULADO = 268;
export const CANDIDATO_BECA_CONTRATADO = 'Contratado';

export const TIPOS_FICHERO_ALUMNO = [
  {id: 87, nombre: "Foto perfil", parent_id: 86},
  {id: 88, nombre: "Curriculum", parent_id: 86},
  {id: 385, nombre: "Otros", parent_id: 86},
]

export const TIPOS_FACTURA = [
  {value: 'venta', label: 'Venta'},
  {value: 'rectificativa', label: 'Rectificativa'},
  {value: 'proforma', label: 'Proforma'}
]

export const MEMORIA_TIPO_TUTOR = 'tutor';
export const MEMORIA_TIPO_INTERMEDIA = 'intermedia_alumno';
export const MEMORIA_TIPO_FINAL= 'final_alumno';

export const SET_PAGINATION = {
  paginaActual: 1,
  rows: 0,
  totalPaginas: 1,
  currentPage: 1,
  perPage: 20,
}

export const CALENDAR_LANG = {
  formatLocale: {
    firstDayOfWeek: 1,
    weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    placeholder: {
      date: 'Selecciona una Fecha',
      dateRange: 'Selecciona un Rango de Fechas'
    }
  },
}

export const QUILL_CONFIG_COMENTARIO = {  // QUILL_CONFIG_SNOW
  theme: 'snow',
  placeholder: 'Escribe un comentario ...',
  modules: {
    toolbar: false,
    // toolbar: [
    //   ["bold", "italic", "underline", "strike"],
      // ['blockquote', 'code-block'],  //
      /*[{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }],  //
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      // [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  //
      [{ 'font': [] }],
      // [{ 'color': [] }, { 'background': [] }],  //
      [{ 'align': [] }],
      ['clean'],
      // ['link', 'image', 'video']  //*/
    // ],
    syntax: {
      // eslint-disable-next-line no-undef
      highlight: text => hljs.highlightAuto(text).value
    }
  }
}

export const QUILL_CONFIG = {  // QUILL_CONFIG_SNOW
  theme: 'snow',
  placeholder: 'Escribe ...',
  modules: {
    toolbar: false,
     toolbar: [
       ["bold", "italic", "underline", "strike", "link"],
     ],
    syntax: {
      // eslint-disable-next-line no-undef
      highlight: text => hljs.highlightAuto(text).value
    }
  }
}

export const QUILL_CONFIG_CONVOCATORIA = {  // QUILL_CONFIG_SNOW
  theme: 'snow',
  placeholder: 'Escribe un texto ...',
  modules: {
    toolbar: false,
     toolbar: [
       ["bold", "italic", "underline", "strike", "link"],
     ],
    syntax: {
      // eslint-disable-next-line no-undef
      highlight: text => hljs.highlightAuto(text).value
    }
  }
}

export const ESTADO_CREADO = 16;//creado
export const ESTADO_NOTIFICADO = 45;//notificado
export const ESTADO_ACEPTADO_CLIENTE = 21;//notificado
export const ESTADO_PENDIENTE_DIAGNOSTICO = 18;//Pendiente diagnóstico
export const ESTADO_PENDIENTE_DIAGNOSTICO_PARCIAL = 19;//Pendiente diagnóstico parcial
export const ESTADO_DIAGNOSTICADO = 20;//Pendiente diagnóstico parcial
export const ESTADO_CERRADO = 48;//Pendiente diagnóstico parcial

export const COMPANY_DATA = {
  company_name: `${process.env.VUE_APP_COMPANY_NAME}` ? `${process.env.VUE_APP_COMPANY_NAME}` : 'Balderma',
  description: `${process.env.VUE_APP_COMPANY_DESCRIPTION}`,
  // email: `${process.env.VUE_APP_CLIENT_APP_MAIL}`,
  logo_path: 'logo/aesthemed-logo.png',
  logo_black_path: 'logo/logo-talento.png',
  logo_small_path: 'logo/aesthemed-small-logo.png',
  // ruta en front
  aviso_legal: './pdfs/BALDERMA_Aviso_Legal.pdf',
  cookies: './pdfs/BALDERMA_Politica_cookies.pdf',
  terminos_condiciones: './pdfs/BALDERMA_Aviso_Legal.pdf',
  // nombre ficheros api
  condicion_general_contratacion: 'CONDICIONES_GENERALES.pdf',
  consentimiento_explicito: 'Consentimiento_pacientes.pdf',
  consentimiento_informado: 'Consentimiento_informado_Formulario.pdf',
  derecho_desistimiento: 'Derecho_desistimiento_balder.pdf',
  manual_fotografia: 'manual_fotografia_Balder.pdf',
  ficha_diagnostico: 'ficha_diagnostico_Balder.pdf'
};

export const CONFIG_PANEL = {
  responsive: true,
}


export const ADMINSTRADORES_MODEL =  { path: 'App\\Models\\Admin\\Administrador', model: 'administradores'}
export const EMPRESA_MODEL =  { path: 'App\\Models\\Base\\Empresas', model: 'empresas'}
export const SEDE_MODEL = { path: 'App\\Models\\Base\\Sedes', model: 'sedes'}
export const MASTER_MODEL = { path: 'App\\Models\\Base\\Masters', model: 'masters'}
export const MATRICULA_MODEL = { path: 'App\\Models\\Base\\Matriculas', model: 'matriculas'}
export const CONTACTO_MODEL = { path: 'App\\Models\\Base\\Contacto', model: 'contactos'}
export const CENTROS_MODEL = { path: 'App\\Models\\Base\\Centros', model: 'centros'}
export const ALUMNO_MODEL = { path: 'App\\Models\\Cursos\\Alumnos', model: 'alumnos'}
export const PRODUCTO_MODEL = { path: 'App\\Models\\Base\\Productos', model: 'productos'}
export const CURSO_MODEL = { path: 'App\\Models\\Base\\Cursos', model: 'cursos'}
export const PROFESORES_MODEL = { path: 'App\\Models\\Base\\Profesores', model: 'profesores'}
export const TIPOLOGIA_MODEL = { path: 'App\\Models\\Base\\Tipologias', model: 'tipologias'}
export const ETIQUETAS_MODEL =  { path: 'App\\Models\\Base\\Etiquetas', model: 'etiquetas'}
export const BECA_MODEL = { path: 'App\\Models\\Base\\Becas', model: 'becas'}
export const CONVOCATORIA_MODEL = { path: 'App\\Models\\Base\\Convocatorias', model: 'convocatorias'}
export const GRUPO_MODEL = { path: 'App\\Models\\Base\\Grupos', model: 'grupos'}
export const LISTA_MODEL = { path: 'App\\Models\\Base\\Listas', model: 'listas'}
export const FACTURA_MODEL = { path: 'App\\Models\\Base\\Facturas', model: 'facturas'}
export const GASTO_MODEL = { path: 'App\\Models\\Base\\Gastos', model: 'gastos'}
export const LESION_MODEL = { path: 'App\\Models\\Base\\Lesiones', model: 'lesiones'}

// MOCK
export const TEMAS_MODEL = { path: 'App\\Models\\Base\\Temas', model: 'temas'}
export const ASIGNATURAS_MODEL = { path: 'App\\Models\\Base\\Asignaturas', model: 'asignaturas'}
export const REUNION_MODEL = { path: 'App\\Models\\Base\\Reuniones', model: 'reuniones'}

